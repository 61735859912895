import React, { useEffect, useRef } from "react"
import Lenis from "@studio-freight/lenis"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useLocation } from "@reach/router"

// Register ScrollTrigger with GSAP
gsap.registerPlugin(ScrollTrigger)

const Layout = ({ children }) => {
  const cursorSize = useRef(null)
  const location = useLocation()

  useEffect(() => {
    const $bigBall = document.querySelector(".cursor__ball--big")
    const $smallBall = document.querySelector(".cursor__ball--small")
    const $newHover = document.querySelector(".cursor__newHover")
    const $hoverables = document.querySelectorAll(".hoverable")
    // Move the cursor
    function onMouseMove(e) {
      gsap.to($bigBall, {
        duration: 0.4,
        x: e.clientX - cursorSize.current.offsetWidth / 2,
        y: e.clientY - cursorSize.current.offsetHeight / 2,
      })
      gsap.to($newHover, {
        duration: 0.4,
        x: e.clientX - $newHover.offsetWidth / 2,
        y: e.clientY - $newHover.offsetHeight / 2,
      })
      gsap.to($smallBall, {
        duration: 0.1,
        x: e.clientX - cursorSize.current.offsetWidth / 4,
        y: e.clientY - cursorSize.current.offsetHeight / 4,
      })
    }

    // Hover an element
    function onMouseHover() {
      gsap.to($bigBall, {
        duration: 0.3,
        scale: 2,
      })
    }
    function onMouseHoverOut() {
      gsap.to($bigBall, {
        duration: 0.3,
        scale: 1,
      })
    }
    if (cursorSize) {
      // Listeners
      document.body.addEventListener("mousemove", onMouseMove)
      for (let i = 0; i < $hoverables.length; i++) {
        $hoverables[i].addEventListener("mouseenter", onMouseHover)
        $hoverables[i].addEventListener("mouseleave", onMouseHoverOut)
      }
    }
    return () => {
      // Listeners
      document.body.removeEventListener("mousemove", onMouseMove)
      for (let i = 0; i < $hoverables.length; i++) {
        $hoverables[i].removeEventListener("mouseenter", onMouseHover)
        $hoverables[i].removeEventListener("mouseleave", onMouseHoverOut)
      }
    }
  }, [cursorSize.current])

  // useEffect(() => {
  //   const lenis = new Lenis({
  //     duration: 1.2,
  //     easing: t => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  //     smooth: true,
  //     direction: "vertical",
  //     smoothTouch: true,
  //   })

  //   const updateScrollTrigger = () => {
  //     ScrollTrigger.refresh()
  //   }

  //   lenis.on("scroll", () => {
  //     ScrollTrigger.update()
  //   })

  //   function raf(time) {
  //     lenis.raf(time)
  //     requestAnimationFrame(raf)
  //   }

  //   requestAnimationFrame(raf)

  //   // Ensure scroll resets properly when navigating pages
  //   const handleLocationChange = () => {
  //     setTimeout(() => {
  //       lenis.scrollTo(0, { duration: 1.2 })
  //       ScrollTrigger.refresh()
  //     }, 100)
  //   }

  //   handleLocationChange()

  //   // Run ScrollTrigger refresh after a slight delay to fix mid-scroll stopping
  //   setTimeout(updateScrollTrigger, 500)

  //   // Cleanup function
  //   return () => {
  //     lenis.destroy()
  //     cancelAnimationFrame(raf) // Ensure it stops when unmounted
  //   }
  // }, [location.pathname])

  return (
    <>
      <div className="cursor">
        <div
          style={{ opacity: 0.5 }}
          ref={cursorSize}
          className="cursor__ball cursor__ball--big "
        >
          <svg height="30" width="30">
            <circle cx="15" cy="15" r="12" stroke-width="0"></circle>
          </svg>
        </div>
        <div className={"cursor__newHover"}>{`VIEW \n PROJECT`}</div>

        <div
          style={{ opacity: 1 }}
          className="cursor__ball cursor__ball--small"
        >
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke-width="0"></circle>
          </svg>
        </div>
      </div>
      {children}
    </>
  )
}

export default Layout
