import React from "react"
import Layout from "./src/hoc/LenisWrapper"
import "./src/styles/global.scss"

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    // If there is a hash (anchor link), let the default scroll behavior work
    return
  }

  // Scroll to the top of the page
  window.scrollTo(0, 0)
}
